import React from 'react'
import './Home.css'
import { Link } from 'react-router-dom'


function Home() {
    return (
        <div className='container'>
            <div className='text'>
                <div className='main-text'>janjua.xyz</div>
                <p className='subtext'> Coming Soon...</p>
                <Link to='/test'>Test</Link>
            </div>
        </div>
    )
}

export default Home